import Accordion from 'accordion-js';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const currentAccordionID = window.location.hash;
  const containers = document.querySelectorAll('.accordion-container');

  if (0 < containers.length) {
    containers.forEach((container) => {
      let currentAccordion = null;
      const openedAccordions = [];

      if (currentAccordionID) {
        currentAccordion = container.querySelector('.ac' + currentAccordionID);
      }

      if (currentAccordion) {
        const currentGroup = currentAccordion.parentNode;
        const currentIndex = Array.prototype.indexOf.call(
          currentGroup.children,
          currentAccordion
        );
        openedAccordions.push(currentIndex);
      }

      new Accordion(container, {
        duration: 400,
        showMultiple: false,
        openOnInit: openedAccordions,
      });
    });
  }
});
